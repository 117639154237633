import {
  Annotation,
  AnnotationLineSubject,
  AreaSeries,
  Axis, // any of these can be non-animated equivalents
  Grid,
  LineSeries,
  Tooltip,
  XYChart,
} from "@visx/xychart"
import { RenderTooltipParams } from "@visx/xychart/lib/components/Tooltip"

import { TClockTypeMaybe } from "src/data/user/user"
import { localizedTimeFormatter } from "src/ui/Graphs/utils"

export function LineChart<Data extends object>({
  clockType,
  yDomain,
  threshold,
  xAccessor,
  yAccessor,
  data,
  toolTip,
  area,
}: {
  /** If x-axis should show time in 24 or 12 hour format */
  clockType: TClockTypeMaybe
  /** How to access the x data point in the data */
  xAccessor: (datum: Data) => Date
  /** How to access the y data point in the data */
  yAccessor: (datum: Data) => number
  /** The data to be used to render the chart */
  data: Data[]
  /** A function which takes a data point and renders a tooltip */
  toolTip: (datum: Data) => React.ReactNode
  /** What the y-scale min/max should be set to [0,100] shows the lowest value 0 and the highest value 100 on the axis. */
  yDomain?: [number, number]
  /**
   * A data point which denotes a threshold line, the x-value does nothing here
   * it just needs to be a valid value. It will take the value generated from
   * `yAccessor` and render a line.
   */
  threshold?: Data
  /** Properties to denote an area around the line */
  area?: {
    yMinAccessor: (datum: Data) => number
    yMaxAccessor: (datum: Data) => number
  }
}) {
  return (
    <XYChart
      height={800}
      xScale={{ type: "time" }}
      yScale={
        !!yDomain ? { type: "linear", domain: yDomain } : { type: "linear" } // If we pass domain: undefined, the graph breaks
      }
    >
      {/**
       * x-axis, localizedTimeformatter is only used to denote between 24 hour
       * and 12 hour clock. otherwise the default d3-time-scale would be enough
       */}
      <Axis
        orientation="bottom"
        tickFormat={localizedTimeFormatter(clockType)}
      />
      {/** y-axis */}
      <Axis orientation="left" />
      {/** The grid */}
      <Grid columns={false} />
      {/** The actual line */}
      <LineSeries
        dataKey="Line 1"
        stroke="black"
        data={data}
        xAccessor={xAccessor}
        yAccessor={yAccessor}
      />
      {/**
       * Shows the area on the graph, where the line is the 'average' this area
       * denotes everything between min/max
       */}
      {area && (
        <AreaSeries
          data={data}
          fill="gray"
          opacity={0.3}
          dataKey={"foo"}
          xAccessor={xAccessor}
          yAccessor={area.yMaxAccessor}
          y0Accessor={area.yMinAccessor}
          renderLine={false}
          enableEvents={false}
        />
      )}

      {/**
       * Shows a threshold line
       */}
      {threshold && (
        <Annotation
          datum={threshold}
          xAccessor={xAccessor}
          yAccessor={yAccessor}
        >
          <AnnotationLineSubject
            orientation="horizontal"
            stroke="red"
            strokeDasharray={5}
          />
        </Annotation>
      )}

      {/**
       * The tooltip
       */}
      <Tooltip
        unstyled
        applyPositionStyle
        snapTooltipToDatumX
        snapTooltipToDatumY
        showSeriesGlyphs
        showVerticalCrosshair
        verticalCrosshairStyle={{
          strokeDasharray: 3,
        }}
        renderTooltip={({ tooltipData }: RenderTooltipParams<Data>) => {
          if (!tooltipData?.nearestDatum) {
            return
          }
          return toolTip(tooltipData.nearestDatum.datum)
        }}
      />
    </XYChart>
  )
}
