import { Dispatch, SetStateAction, useState } from "react"
import styled from "styled-components"

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
} from "@material-ui/core"
import { format } from "date-fns"

import { LineChart } from "src/components/VisxWorkshop/LineChart"
import { brandText, semanticEmergency, semanticGood } from "src/ui/colors"
import { MainView } from "src/ui/Layout/MainView"
import { MText } from "src/ui/MText"

import sound_level from "./data/sound_level.json"

const soundLevelData = sound_level

export function VisxWorkshop() {
  const [yDomain, setYDomain] = useState<[number, number]>([0, 100])
  const [showArea, setShowArea] = useState(true)
  const [threshold, setThreshold] = useState<(typeof soundLevelData.values)[0]>(
    {
      datetime: "2021-01-01", //just valid datestring
      value: 0,
      min: 0,
      max: 0,
    }
  )
  const [showThreshold, setShowThreshold] = useState(true)

  return (
    <MainView title="workshop">
      <MText variant="heading3">Example LineChart</MText>
      <LineChartDemoControls
        yDomain={yDomain}
        setYDomain={setYDomain}
        showThreshold={showThreshold}
        setShowThreshold={setShowThreshold}
        showArea={showArea}
        setShowArea={setShowArea}
        threshold={threshold}
        setThreshold={setThreshold}
      />

      <LineChart
        clockType={"24"}
        xAccessor={(datum) => new Date(datum.datetime)}
        yAccessor={(datum) => datum.value}
        data={soundLevelData.values}
        threshold={showThreshold ? threshold : undefined}
        area={
          showArea
            ? {
                yMaxAccessor: (d) => d.max,
                yMinAccessor: (d) => d.min,
              }
            : undefined
        }
        yDomain={yDomain}
        toolTip={({ value, min, max, datetime }) => {
          return (
            <Box>
              <Header>{getDateLabel(new Date(datetime))}</Header>
              <Content>
                <Row>
                  Average: <Value>{Math.round(value)}</Value>
                </Row>
                <Row>
                  <DownArrow>↓</DownArrow>
                  <Value>{Math.round(min)}</Value> {"dB"}
                  <UpArrow>↑</UpArrow>
                  <Value>{Math.round(max)}</Value> {"dB"}
                </Row>
              </Content>
            </Box>
          )
        }}
      />
    </MainView>
  )
}

function LineChartDemoControls({
  yDomain,
  setYDomain,
  showThreshold,
  setShowThreshold,
  showArea,
  setShowArea,
  threshold,
  setThreshold,
}: {
  yDomain: [number, number]
  setYDomain: Dispatch<SetStateAction<[number, number]>>
  showThreshold: boolean
  setShowThreshold: Dispatch<SetStateAction<boolean>>
  showArea: boolean
  setShowArea: Dispatch<SetStateAction<boolean>>
  threshold: (typeof soundLevelData.values)[0]
  setThreshold: Dispatch<SetStateAction<(typeof soundLevelData.values)[0]>>
}) {
  return (
    <FormControl>
      <div style={{ display: "grid", gap: "1rem" }}>
        <TextField
          type="number"
          label="Y max scale"
          value={yDomain[1]}
          onChange={(e) =>
            setYDomain(([min, _]) => {
              const value = parseInt(e.target.value)
              if (value < min + 10) return [value - 10, value]
              return [min, value]
            })
          }
        />
        <TextField
          type="number"
          label="Y min scale"
          value={yDomain[0]}
          onChange={(e) =>
            setYDomain(([_, max]) => {
              const value = parseInt(e.target.value)
              if (value > max - 10) return [value, value + 10]
              return [value, max]
            })
          }
        />
        <div style={{ display: "flex", gap: "0.5rem" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showThreshold}
                value={showThreshold}
                onChange={() => setShowThreshold((p) => !p)}
              ></Checkbox>
            }
            label="Threshold"
          />
          <TextField
            disabled={!showThreshold}
            type="number"
            label="threshold value"
            value={threshold.value}
            onChange={(e) =>
              setThreshold(({ datetime }) => {
                const value = parseInt(e.target.value)
                return {
                  datetime,
                  max: value,
                  min: value,
                  value,
                }
              })
            }
          />
        </div>
        <FormControlLabel
          control={
            <Checkbox
              checked={showArea}
              value={showArea}
              onChange={() => setShowArea((p) => !p)}
            ></Checkbox>
          }
          label="Area"
        />
      </div>
    </FormControl>
  )
}

const getDateLabel = (date: Date) => {
  try {
    return format(date, "EEE, MMM d HH:mm")
  } catch (err) {
    return date.toDateString()
  }
}

const Box = styled.div`
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border-radius: 0.25rem;
  min-width: 170px;
`

const Header = styled.div`
  border-radius: 0.25rem 0.25rem 0 0;
  padding: 0.875rem 1rem;
  background: ${brandText};
  color: white;
`

const Content = styled.div`
  background: white;
  font-size: 1rem;
  padding: 0.875rem 1rem;
  border-radius: 0 0 0.25rem 0.25rem;
`

const Row = styled.div`
  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`

const DownArrow = styled.span`
  color: ${semanticGood};
`

const UpArrow = styled.span`
  color: ${semanticEmergency};
`

const Value = styled.span`
  font-weight: bold;
`
